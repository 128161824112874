
import { Options, mixins } from "vue-class-component"
import CommonPage from "@/mixins/CommonPage"
import CustomModal from "@/components/Modal/CustomModal.vue"
import InviteBlockV2 from "@/components/InviteBlock/InviteBlockV2.vue"
import { startScreenerEvent } from "@/api/services/EventService"
import { $vfm } from "vue-final-modal"
import ParticipantsModal from "@/components/Modal/ParticipantsModal.vue"

@Options({
  components: {
    InviteBlockV2,
    CustomModal,
    ParticipantsModal
  }
})
export default class Welcome extends mixins(CommonPage) {
  inviteBlockImagesConfig = {
    incentive: {
      regular: "incentive",
      mobile: "incentive"
    },
    clock: {
      regular: "clock",
      mobile: "clock"
    },
    online: {
      regular: "wifi-circle-icon",
      mobile: "wifi-circle-icon"
    }
  }

  hideModal(name: string): void {
    $vfm.hide(name)
  }

  onModalOpenClick(name: string): void {
    $vfm.show(name)
  }

  onStart(): void {
    if (this.showScreener) {
      this.$router.push({
        name: "SurveyDisclaimerV2",
        query: {
          id: this.id
        }
      })
      startScreenerEvent(this.id as string)
    } else {
      this.onAcceptInvite()
    }
  }
}
